code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loaderBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 5px solid transparent;
  border-top: 5px solid black;
  border-right: 5px solid black;
  border-left: 5px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.schedule {
  background-color: #faf6f3;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .schedule-header {
    background-color: #faf6f3;
    z-index: 999;
  }
  .schedule-body {
    padding-bottom: 1000px;
    overflow: auto;
    height: 100%;
  }
}

.error-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty {
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
}

.header-filter {
  display: flex;
  .css-2b097c-container {
    width: 200px;
    margin: 5px;
  }
}

.header-days-row {
  display: flex;
  gap: 5px;
  width: 100%;
  .date {
    min-width: 10px;
    width: 100%;

    div {
      background-color: #ffffff;
      margin: 10px 0px;
      padding: 14px;
      text-align: center;
      text-transform: uppercase;
    }
    .daytitle {
      white-space: nowrap;
      overflow: hidden;
      background-color: white;
      .weekday {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .empty {
    .daytitle {
      background-color: transparent;
    }
  }
}

.range {
  display: flex;
  gap: 5px;
  width: 100%;

  .range-title {
    box-sizing: border-box;
    background-color: #e1e1e1;
    height: 100%;
    padding: 10px;
  }
  .course {
    background-color: #598045;
    height: 100%;
    width: 100%;
    text-align: center;
    color: black;
    word-wrap: break-word;
  }
}

.header-navigation {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  position: absolute;
  top: 90px;
  width: 100%;
}

.day-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.schedule ul {
  list-style-type: none;
}

.classes {
  display: flex;
  flex-direction: column;
  min-width: 10px;
  gap: 5px 0px;
  width: 100%;
}

.hidden {
  visibility: hidden;
}

.hovered {
  opacity: 0.5;
}

.time-range {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
  .range-title-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .actnam {
    width: 100%;
  }
}
